import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import NewsletterSection from "../components/newsletter-section";
import Hero from "../components/hero";
import { ImFacebook, ImTwitter, ImYoutube } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";

const ContactUsPage = ({ transitionStatus }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Contact-Hero" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Us",
        item: {
          url: `${siteUrl}/contact-us`,
          id: `${siteUrl}/contact-us`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Contact Us | The Ella Roberta Foundation"
        description="Contact page for The Ella Roberta Foundation."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: "Contact Us | The Ella Roberta Foundation",
          description: "Contact page for The Ella Roberta Foundation.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <div className="bg-background">
          <Hero
            title={<span>CONTACT US</span>}
            backgroundImage={
              data.heroImg?.localFile.childImageSharp.gatsbyImageData
            }
          />

          <section className="bg-primary py-5 py-lg-8">
            <Container>
              <Row className="justify-content-center">
                <Col className="text-center">
                  <h2 className="fs-1 text-white pb-4">GET IN TOUCH</h2>
                  <p className="fs-4 gobold-thin text-white pb-4">
                    PLEASE CONTACT US WITH IDEAS OR MEDIA QUERIES.
                  </p>
                  {/* <a
										href="mailto:rosamund@ellaroberta.org"
										className="white-link d-block"
									>
										rosamund@ellaroberta.org
									</a> */}
                  {/* <a href="mailto:media@ellaroberta.org" className="white-link">
                    media@ellaroberta.org
                  </a> */}
                  <ul className="ps-0 mb-1 mt-4">
                    <li className="d-inline-block me-3 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="white-link fs-1 social-icon"
                        href="https://www.facebook.com/Ella-Roberta-Family-Foundation-229573000528354/"
                      >
                        <ImFacebook />
                      </a>
                    </li>
                    <li className="d-inline-block me-3">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="white-link fs-1 social-icon"
                        href="https://twitter.com/EllaRobertaFdn?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                      >
                        <ImTwitter />
                      </a>
                    </li>
                    <li className="d-inline-block me-3 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" white-link fs-1 social-icon"
                        href="https://www.instagram.com/theellarobertafamilyfoundation/?hl=en"
                      >
                        <BsInstagram />
                      </a>
                    </li>
                    <li className="d-inline-block  ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" white-link fs-1 social-icon"
                        href="https://www.youtube.com/channel/UCn_mfXJanHMcNUgYYvkk4bQ"
                      >
                        <ImYoutube />
                      </a>
                    </li>
                  </ul>
                  <Link to="/rss.xml" className="white-link mt-4">
                    Follow our blog
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
          <ContactForm />
          {/* <NewsletterSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ContactUsPage;
