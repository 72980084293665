import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

function ContactForm() {
	const [state, handleSubmit] = useForm("moqozgwq");
	if (state.succeeded) {
		return (
			<div>
				<section className="bg-background py-5 py-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col className="" lg={9} xl={7}>
								<h2 className="fs-1  text-center pb-3">SEND US A MESSAGE</h2>{" "}
								<p className="text-center pb-3 ">
									Please allow up to seven working days for a response.
								</p>
								<Form className="w-100" onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId="name">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<Form.Label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
														for="name"
													>
														NAME
													</Form.Label>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Control
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="text"
														value=""
														name="name"
														required
														className=" name  h-100 fs-5 ps-3   contact-form w-100"
														id="name"
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Name"
										field="name"
										errors={state.errors}
									/>
									<Form.Group className="mb-3" controlId="email">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<Form.Label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
														for="email"
													>
														EMAIL
													</Form.Label>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Control
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="email"
														value=""
														name="email"
														required
														className="   h-100 fs-5 ps-3   contact-form w-100"
														id="email"
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Email"
										field="email"
										errors={state.errors}
									/>
									<Form.Group className="mb-3" controlId="mobile">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<Form.Label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
														for="mobile"
													>
														MOBILE
													</Form.Label>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Control
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="tel"
														value=""
														name="mobile"
														required
														className="   h-100 fs-5 ps-3   contact-form w-100"
														id="mobile"
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Mobile"
										field="mobile"
										errors={state.errors}
									/>
									<Form.Group className="mb-3" controlId="company">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<Form.Label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
														for="company"
													>
														COMPANY
													</Form.Label>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Control
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="text"
														value=""
														name="company"
														required
														className="  h-100 fs-5 ps-3   contact-form w-100"
														id="company"
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Company"
										field="company"
										errors={state.errors}
									/>

									<Form.Group className="mb-3" controlId="subject">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<Form.Label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 text-uppercase w-100 bg-primary mb-0 text-white"
														htmlFor="subject"
													>
														enquiry
													</Form.Label>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Select
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														value=""
														name="subject"
														required
														className="  h-100 fs-5 ps-3   contact-form w-100"
														id="subject"
													>
														<option value="">Select an enquiry</option>
														<option value="Form submission from Ella Roberta subject Media Enquiry">
															Media
														</option>
														<option value="Form submission from Ella Roberta subject Other Enquiry">
															Other
														</option>
													</Form.Select>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Subject"
										field="subject"
										errors={state.errors}
									/>

									<Form.Group className="mb-3" controlId="message">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0 h-100 ">
												<Col
													style={{ minHeight: "100%" }}
													className=""
													xs={4}
													md={2}
												>
													<div
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="h-100 d-flex align-items-center bg-primary"
													>
														<Form.Label
															className="fs-6  ps-3  w-100  mb-0 text-white"
															for="message"
														>
															MESSAGE
														</Form.Label>
													</div>
												</Col>
												<Col className="" xs={8} md={10}>
													<Form.Control
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														as="textarea"
														rows={3}
														value=""
														name="message"
														required
														className="  h-100 fs-5 ps-3   contact-form w-100"
														id="message"
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>
									<ValidationError
										prefix="Message"
										field="message"
										errors={state.errors}
									/>

									<Button
										variant="primary"
										type="submit"
										id="contact-send-btn"
										name="contact-send-btn"
										className="button btn fs-5 mb-4 w-100 py-3 "
									>
										SEND MESSAGE
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</section>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<section className="bg-background py-5 py-lg-8">
			<Container>
				<Row className="justify-content-center">
					<Col className="" lg={9} xl={7}>
						<h2 className="fs-1  text-center pb-3">SEND US A MESSAGE</h2>{" "}
						<p className="text-center pb-3 ">
							Please allow up to seven working days for a response.
						</p>
						<Form className="w-100" onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="name">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0">
										<Col className="" xs={4} md={2}>
											<Form.Label
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
												for="name"
											>
												NAME
											</Form.Label>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Control
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												type="text"
												value=""
												name="name"
												required
												className=" name  h-100 fs-5 ps-3   contact-form w-100"
												id="name"
											/>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
							<Form.Group className="mb-3" controlId="email">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0">
										<Col className="" xs={4} md={2}>
											<Form.Label
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
												for="email"
											>
												EMAIL
											</Form.Label>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Control
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												type="email"
												value=""
												name="email"
												required
												className="   h-100 fs-5 ps-3   contact-form w-100"
												id="email"
											/>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
							<Form.Group className="mb-3" controlId="mobile">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0">
										<Col className="" xs={4} md={2}>
											<Form.Label
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
												for="mobile"
											>
												MOBILE
											</Form.Label>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Control
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												type="tel"
												value=""
												name="mobile"
												required
												className="   h-100 fs-5 ps-3   contact-form w-100"
												id="mobile"
											/>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Mobile"
								field="mobile"
								errors={state.errors}
							/>
							<Form.Group className="mb-3" controlId="company">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0">
										<Col className="" xs={4} md={2}>
											<Form.Label
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
												for="company"
											>
												COMPANY
											</Form.Label>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Control
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												type="text"
												value=""
												name="company"
												required
												className="  h-100 fs-5 ps-3   contact-form w-100"
												id="company"
											/>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Company"
								field="company"
								errors={state.errors}
							/>

							<Form.Group className="mb-3" controlId="subject">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0">
										<Col className="" xs={4} md={2}>
											<Form.Label
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="fs-6 ps-3 py-3 text-uppercase w-100 bg-primary mb-0 text-white"
												htmlFor="subject"
											>
												enquiry
											</Form.Label>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Select
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												value=""
												name="subject"
												required
												className="  h-100 fs-5 ps-3   contact-form w-100"
												id="subject"
											>
												<option value="">Select an enquiry</option>
												<option value="Form submission from Ella Roberta subject Media Enquiry">
													Media
												</option>
												<option value="Form submission from Ella Roberta subject Other Enquiry">
													Other
												</option>
											</Form.Select>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Subject"
								field="subject"
								errors={state.errors}
							/>

							<Form.Group className="mb-3" controlId="message">
								<div style={{ borderRadius: "100px" }} className="w-100 mb-4 ">
									<Row className="gx-0 h-100 ">
										<Col
											style={{ minHeight: "100%" }}
											className=""
											xs={4}
											md={2}
										>
											<div
												style={{
													borderTopLeftRadius: "100px",
													borderBottomLeftRadius: "100px",
												}}
												className="h-100 d-flex align-items-center bg-primary"
											>
												<Form.Label
													className="fs-6  ps-3  w-100  mb-0 text-white"
													for="message"
												>
													MESSAGE
												</Form.Label>
											</div>
										</Col>
										<Col className="" xs={8} md={10}>
											<Form.Control
												style={{
													borderTopRightRadius: "100px",
													borderBottomRightRadius: "100px",
												}}
												as="textarea"
												rows={3}
												value=""
												name="message"
												required
												className="  h-100 fs-5 ps-3   contact-form w-100"
												id="message"
											/>
										</Col>
									</Row>
								</div>
							</Form.Group>
							<ValidationError
								prefix="Message"
								field="message"
								errors={state.errors}
							/>

							<Button
								variant="primary"
								type="submit"
								id="contact-send-btn"
								name="contact-send-btn"
								className="button btn fs-5 mb-4 w-100 py-3 "
							>
								SEND MESSAGE
							</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
export default ContactForm;
