import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function NewsletterSection() {
	return (
		<section className="bg-background py-5 py-lg-8">
			<Container>
				<Row className="justify-content-center">
					<Col className="" lg={9} xl={7}>
						<div id="mc_embed_signup">
							<form
								action="https://ellaroberta.us14.list-manage.com/subscribe/post?u=4d93d7be89d2b92343c2f72a3&amp;id=24bd088468"
								method="post"
								id="mc-embedded-subscribe-form"
								name="mc-embedded-subscribe-form"
								class="validate"
								target="_blank"
								novalidate
							>
								<div id="mc_embed_signup_scroll">
									<h2 className="fs-1  text-center pb-3">
										SIGN UP TO OUR NEWSLETTER
									</h2>
									<p className="text-center mb-5">
										We'd love to keep in touch and let you know what the
										Foundation has been up to. Give us your email address and
										we'll send you our quarterly newsletter.
									</p>

									<div class="mc-field-group w-100">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3 w-100 bg-primary mb-0 text-white"
														for="mce-EMAIL"
													>
														EMAIL
													</label>
												</Col>
												<Col className="" xs={8} md={10}>
													<input
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="email"
														value=""
														name="EMAIL"
														class="required email h-100 position-reltaive fs-5 ps-3  contact-form w-100"
														id="mce-EMAIL"
													/>
												</Col>
											</Row>
										</div>
									</div>
									<div class="mc-field-group">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3   w-100 bg-primary mb-0 text-white"
														for="mce-FNAME"
													>
														FIRST NAME
													</label>
												</Col>
												<Col className="" xs={8} md={10}>
													<input
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="text"
														value=""
														name="FNAME"
														class="h-100 position-reltaive fs-5 ps-3  contact-form w-100 "
														id="mce-FNAME"
													/>
												</Col>
											</Row>
										</div>
									</div>
									<div class="mc-field-group">
										<div
											style={{ borderRadius: "100px" }}
											className="w-100 mb-4 "
										>
											<Row className="gx-0">
												<Col className="" xs={4} md={2}>
													<label
														style={{
															borderTopLeftRadius: "100px",
															borderBottomLeftRadius: "100px",
														}}
														className="fs-6 ps-3 py-3  w-100 bg-primary mb-0 text-white"
														for="mce-LNAME"
													>
														LAST NAME
													</label>
												</Col>
												<Col className="" xs={8} md={10}>
													<input
														style={{
															borderTopRightRadius: "100px",
															borderBottomRightRadius: "100px",
														}}
														type="text"
														value=""
														name="LNAME"
														class="h-100 position-reltaive fs-5 ps-3 contact-form w-100"
														id="mce-LNAME"
													/>
												</Col>
											</Row>
										</div>
									</div>
									<div id="mce-responses" class="clear foot">
										<div
											class="response"
											id="mce-error-response"
											style="display:none"
										></div>
										<div
											class="response"
											id="mce-success-response"
											style="display:none"
										></div>
									</div>
									<div
										style="position: absolute; left: -5000px;"
										aria-hidden="true"
									>
										<input
											type="text"
											name="b_4d93d7be89d2b92343c2f72a3_24bd088468"
											tabindex="-1"
											value=""
										/>
									</div>
									<div class="optionalParent">
										<div class="clear foot text-center">
											<Button
												variant="primary"
												type="submit"
												value="subscribe"
												name="subscribe"
												id="mc-embedded-subscribe"
												className="button btn fs-5 mb-4 w-100 py-3 "
											>
												SIGN UP NOW
											</Button>
											<p class="brandingLogo">
												<a
													href="http://eepurl.com/h46alD"
													title="Mailchimp - email marketing made easy and fun"
												>
													<img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
												</a>
											</p>
										</div>
									</div>
								</div>
							</form>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
export default NewsletterSection;
